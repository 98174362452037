import { iIcon } from "./interfaces"

function TextAlignCenter({ size, color = '#227baf' } : iIcon) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 6H3V7.5H21V6Z" fill={color} />
      <path d="M18 9.75H6V11.25H18V9.75Z" fill={color} />
      <path d="M18 17.25H6V18.75H18V17.25Z" fill={color} />
      <path d="M21 13.5H3V15H21V13.5Z" fill={color} />
    </svg>
  )
}
export default TextAlignCenter
