import { styled } from 'baseui'
import { H6, ParagraphSmall } from 'baseui/typography'

import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import useAppContext from '../../../hooks/useAppContext'

const ContainerCard = styled('div', props => ({
  backgroundColor: 'red',
  boxSizing: 'border-box',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '.25em',
}))

const ContainerItems = styled('div', () => ({
  background: '#fff',
  marginTop: '15px',
  borderRadius: '.25em',
  width: '100%',
  boxSizing: 'border-box',
}))

const ItemCheck = ({ title = '', checked = false }: { title: string; checked: boolean }) => {
  return (
    <Checkbox
      checked={checked}
      labelPlacement={LABEL_PLACEMENT.right}
      title={title}
      overrides={{
        Label: {
          style: {
            fontWeight: '400',
          },
        },
        Root: {
          style: {
            margin: '5px 0px',
          },
        },
      }}
    >
      {title}
    </Checkbox>
  )
}

function CardRed() {
  const { itemsCard, dataCardRed } = useAppContext()
  return (
    <ContainerCard className='parentCard'>
      <H6 margin={0} color="#fff" $style={{ textAlign: 'center' }}>
        Seguridad
      </H6>
      <ContainerItems style={{ width: 'fit-content', padding: '5px 30px' }}>
        <ParagraphSmall margin={0}>
          Tarjeta N° <b>{dataCardRed?.numberCard}</b>
        </ParagraphSmall>
      </ContainerItems>
      <ContainerItems style={{ padding: '10px' }}>
        <ParagraphSmall margin={'2px 0px'}>
          <b>Linea: </b>
          {dataCardRed?.line}
        </ParagraphSmall>
        <ParagraphSmall margin={'2px 0px'}>
          <b>Equipo: </b>
          {dataCardRed?.team}
        </ParagraphSmall>

        <ParagraphSmall margin={'2px 0px'}>
          <b>Fecha: </b>
          {dataCardRed?.date}
        </ParagraphSmall>
      </ContainerItems>
      <ContainerItems style={{ padding: '15px 10px' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '50% 50%', columnGap: '5px' }}>
          <H6 $style={{gridColumn : '1/3',textAlign : 'center'}} color='primaryA' margin={'0px 0px 5px'} >Tipos de problemas</H6>
          {itemsCard.map((item, key) => (
            <div key={`item_chek_${key}`}>
              <ItemCheck checked={item.active} title={item.name}></ItemCheck>
            </div>
          ))}
          <div style={{ gridColumn: '1/3' }}>
            <ParagraphSmall margin={'15px 0px 0px'}>
              *En caso de marcar "otros" identificar el problema en observaciones
            </ParagraphSmall>
          </div>
        </div>
      </ContainerItems>
      <ContainerItems style={{ padding: '15px 10px' }}>
        <ParagraphSmall margin={'5px 0px'}>
          <b>Observaciones: </b> {dataCardRed?.observations}
        </ParagraphSmall>
      </ContainerItems>

      <ContainerItems style={{ padding: '15px 10px' }}>
      <ParagraphSmall margin={'5px 0px'}>
          <b>Nombre completo: </b>
          {dataCardRed?.fullname} - {dataCardRed?.email}
        </ParagraphSmall>
      </ContainerItems>
    </ContainerCard>
  )
}

export default CardRed
