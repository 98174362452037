import { useEffect, useRef } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import useAppContext from './hooks/useAppContext'

import logo from './assets/logo2.png'

function Container({ children }) {
  const containerRef = useRef<HTMLDivElement>()

  const { isMobile, setIsMobile, setWidthW } = useAppContext()

  const updateMediaQuery = (value: number) => {
    setWidthW(value)
    if (!isMobile && value >= 767) {
      setIsMobile(false)
    } else if (!isMobile && value < 767) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    const containerElement = containerRef.current
    const containerWidth = containerElement.clientWidth
    updateMediaQuery(containerWidth)
    const resizeObserver = new ResizeObserver(entries => {
      const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {}
      updateMediaQuery(width)
    })
    resizeObserver.observe(containerElement)
    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>

      <main ref={containerRef} style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        {children}
      </main>

      <footer>
        <p>Desarrollado por <img src={logo} width="45" alt="logo-lti" title="LTI" /></p>
      </footer>
    </>
  )
}

export default Container
