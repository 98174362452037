function Redo({ size } : {size : number}) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7382 13.0768L20.4507 9.36438C20.6248 9.19026 20.763 8.98355 20.8572 8.75603C20.9515 8.52852 21 8.28467 21 8.0384C21 7.79214 20.9515 7.54828 20.8572 7.32077C20.763 7.09326 20.6248 6.88654 20.4507 6.71243L16.7382 3L15.6748 4.06348L18.8997 7.28842H5.32495C4.72354 7.27451 4.14114 7.49957 3.70535 7.91427C3.26955 8.32897 3.01592 8.89951 3 9.50087V17.8257C3.01592 18.4271 3.26955 18.9976 3.70535 19.4123C4.14114 19.827 4.72354 20.0521 5.32495 20.0382H19.4997V18.5382H5.32495C5.12136 18.5519 4.92059 18.4847 4.76616 18.3514C4.61173 18.218 4.51609 18.0291 4.49997 17.8257V9.50087C4.51609 9.29746 4.61173 9.1086 4.76616 8.97523C4.92059 8.84186 5.12136 8.77473 5.32495 8.78839H18.9049L15.68 12.0133L16.7382 13.0768Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Redo
