import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import Provider from './Providers'
import Container from './Container'
import Routes from './Routes'
import "./index.css";

ReactDOM.render(
  <Provider>
    <Container>
      <Routes />
    </Container>
  </Provider>,
  document.getElementById('root')
)
reportWebVitals()
