import { createTheme } from 'baseui'
import { ThemePrimitives} from 'baseui/theme'


const primitives : ThemePrimitives = {
  primaryA: '#0091CE',
  primaryB: '#f5bd01',
  primaryFontFamily: 'Roboto',
  primary: '#227baf',
  primary50: '#227bafc',
  primary100: '#0090cece',
  primary200: 'rgba(34, 123, 175, 0.2)',
  primary300: '#227baf4c',
  primary400: '#227baf66',
  primary500: '#227baf7f',
  primary600: '#227baf99',
  primary700: '#227bafb2',
  accent: '#276EF1',
  accent50: '#EFF3FE',
  accent100: '#D4E2FC',
  accent200: '#A0BFF8',
  accent300: '#5B91F5',
  accent400: '#276EF1',
  accent500: '#1E54B7',
  accent600: '#174291',
  accent700: '#102C60',
  negative: '#E11900',
  negative50: '#FFEFED',
  negative100: '#FED7D2',
  negative200: '#F1998E',
  negative300: '#E85C4A',
  negative400: '#E11900',
  negative500: '#AB1300',
  negative600: '#870F00',
  negative700: '#5A0A00',
  warning: '#FFC043',
  warning50: '',
  warning100: '',
  warning200: '',
  warning300: '',
  warning400: '',
  warning500: '',
  warning600: '',
  warning700: '',
  positive: '#03703C',
  positive50: '#E6F2ED',
  positive100: '#ADDEC9',
  positive200: '#66D19E',
  positive300: '#06C167',
  positive400: '#048848',
  positive500: '#03703C',
  positive600: '#03582F',
  positive700: '#10462D',
  mono100: '#FFFFFF',
  mono200: '#F6F6F6',
  mono300: '#EEEEEE',
  mono400: '#E2E2E2',
  mono500: '#CBCBCB',
  mono600: '#AFAFAF',
  mono700: '#6B6B6B',
  mono800: '#545454',
  mono900: '#333333',
  mono1000: '#000000',
  rating200: '#FFE3AC',
  rating400: '#FFC043'
}

const overrides = {
  typography: {
    HeadingXSmall : {
      fontFamily : 'Poppins'
    },
    HeadingSmall : {
      fontFamily : 'Poppins'
    },
    HeadingMedium : {
      fontFamily : 'Poppins'
    },
    HeadingLarge : {
      fontFamily : 'Poppins'
    },
    HeadingXLarge : {
      fontFamily : 'Poppins'
    },
    HeadingXXLarge : {
      fontFamily : 'Poppins'
    }
  },
  // color para el contenido primario, por ejemplo parrafos.
  colors : {
    contentPrimary : '#575757',
    buttonPrimaryFill : primitives.primaryA,
    buttonSecondaryFill : primitives.primaryB,
    buttonSecondaryText : '#fff',
    buttonSecondaryHover : '#daa805',
  }, 
  borders : {
    buttonBorderRadius : '.25em',
    inputBorderRadius : '.25em'
  }
}

export const theme = createTheme(primitives, overrides)
