export enum columns {
  col_1 = 'calc(100% / 12)',
  col_2 = 'calc(100% / 6)',
  col_3 = 'calc(100% / 4)',
  col_4 = 'calc(100% / 3)',
  col_5 = 'calc(100% * 5 / 12)',
  col_6 = 'calc(100% / 2)',
  col_7 = 'calc(100%  * 7 / 12)',
  col_8 = 'calc(100% * 2 / 3)',
  col_9 = 'calc(100% * 3 / 4)',
  col_10 = 'calc(100% * 5 / 6)',
  col_11 = 'calc(100% * 11 / 12)',
  col_12 = 'calc(100%)',
}

export async function toggleFullScreen(): Promise<void> {
  if (!document.fullscreenElement) {
    await document.documentElement.requestFullscreen()
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }
}

export const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const getFecha = () => {
  const fecha = new Date()
  return transformFecha(fecha.toISOString())
}

export const transformFecha = (fecha: string) => {
  try {
    return new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
    }).format(new Date(fecha))
  } catch (error) {
    return 'Fecha inválida:' + fecha
  }
}


export class ExceptionCustom extends Error{
  descriptions : Array<string> = []
  constructor(value : string){
    super();
    throw new Error(value);
  }
}

export const RandonNumber= (min : number , max : number) : number => {
  if(max < min ){
    throw new Error('El maximo no puede ser menor que el minimo')
  }
  return Math.floor((Math.random() * (max - min + 1)) + min);
}