import Welcome from './Welcome';
import Mobile from './Mobile';
import Tutorial from './Tutorial';
import Panels from './Panels';
import ModalComplete from './Modals/ModalComplete';
import ModalIncorrect from './Modals/ModalIncorrect';
import InfoGame from './Modals/InfoGame';
import Navbar from './Navbar';
import Loading from './Loading';
import ErrorParams from './Error';
import LoaderCanvas from './LoaderCanvas';
import ModalEleccionIncorrect from './Modals/ModalEleccionIncorrect';
import Tarjetas from './Tarjetas';
import WorkTable from './POEV'

class ComponentsItems {
  static Welcome = Welcome
  static Mobile = Mobile
  static Tutorial = Tutorial
  static Panels = Panels
  static ModalComplete = ModalComplete
  static ModalIncorrect = ModalIncorrect
  static InfoGame = InfoGame
  static Navbar = Navbar
  static Loading = Loading
  static ErrorParams = ErrorParams
  static LoaderCanvas = LoaderCanvas
  static EleccionIncorrecta = ModalEleccionIncorrect
  static Tarjetas = Tarjetas
  static WorkTable = WorkTable
}

export default ComponentsItems