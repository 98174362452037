import {
  collection,
  getDocs,
  query,
  QueryDocumentSnapshot,
  where,
  addDoc,
  Timestamp,
} from 'firebase/firestore'
import db from '../firebase/index'
import { getDownloadURL, getStorage, ref, UploadResult, uploadString } from 'firebase/storage'
import { customAlphabet } from 'nanoid'

interface iUserData {
  email: string
  fullname: string
}
interface iGameData {
  name : string,
  id : string
}

// se pone T para poder reutlizar

const convert = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
})

export async function getData<T>(typeWhere: string, valueWhere: string, collectionName: string): Promise<T> {
  const responseFB = await getDocs(
    query(collection(db, collectionName).withConverter(convert<T>()), where(typeWhere, '==', valueWhere))
  )
  const data: T[] = []
  responseFB.forEach(element => {
    data.push(element.data())
  })
  if (data.length > 0) return data[0]
  return null
}

export async function getImage(path: string): Promise<string> {
  const storage = getStorage()
  const spaceRef = ref(storage, path)
  return await getDownloadURL(spaceRef)
}

export async function sendImage(base: string): Promise<UploadResult> {
  const storage = getStorage()
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuv', 15)
  const ran = nanoid()
  const spaceRef = ref(storage, `resultados/${ran}.png`)
  return await uploadString(spaceRef, base, 'data_url')
}

export async function saveDataResults(user: iUserData,game : iGameData, path: string): Promise<void> {
  await addDoc(collection(db,'resultados'),{
    email : user.email,
    fullname : user.fullname,
    idGame : game.id,
    nameGame : game.name,
    path,
    date : Timestamp.fromDate(new Date())
  })
}
