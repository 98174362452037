import { panelListItemPOEV,panelListItemCARD, panelListItemLUP } from '../../constants/app-options' 
import useAppContext from '../../hooks/useAppContext'
import { styled } from 'baseui'
import PanelListItem from './PanelListItem'
import { useEffect, useState } from 'react'

const Container = styled('div', props => ({
  width: '84px',
  backgroundColor: props.$theme.colors.white,
}))
 
function PanelsList() {
  const { activePanel,dataGame } = useAppContext()

  const [listaPanel, setList] = useState([])
  

  useEffect(()=>{
    if(dataGame.name=== 'LUP'){
      setList(panelListItemLUP)
    }else if(dataGame.name=== 'POEV'){
      setList(panelListItemPOEV)
    }else if(dataGame.name === 'Tarjetas'){
      setList(panelListItemCARD)
    }
  },[dataGame])
 
  return (
    <Container>
      {listaPanel.map(panelListItem => (
        <PanelListItem
          label={panelListItem.name}
          title={panelListItem.title}
          key={panelListItem.name}
          icon={panelListItem.name}
          activePanel={activePanel}
        />
      ))}
    </Container>
  )
}

export default PanelsList
