import { SVGProps } from 'react'

function TextSpacing(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176.292 176.292" xmlSpace="preserve" {...props}>
      <path d="M144.768 78.685c4.321-16.125 2.103-32.968-6.244-47.426C127.392 11.978 106.633 0 84.347 0c-10.912 0-21.7 2.899-31.199 8.383-14.458 8.347-24.8 21.825-29.121 37.951-4.321 16.125-2.104 32.968 6.244 47.426 11.132 19.281 31.892 31.259 54.178 31.259 8.41 0 16.745-1.729 24.474-5.03l32.507 56.303 12.99-7.5-32.508-56.305c11.175-8.354 19.19-20.117 22.856-33.802zm-36.62 24.961c-7.221 4.169-15.417 6.373-23.699 6.373-16.944 0-32.727-9.104-41.188-23.759-6.344-10.988-8.029-23.789-4.745-36.043C41.8 37.96 49.66 27.717 60.648 21.373 67.87 17.204 76.064 15 84.347 15c16.944 0 32.726 9.104 41.187 23.759 6.344 10.988 8.029 23.789 4.746 36.044-3.284 12.256-11.144 22.499-22.132 28.843zm-16.25-48.637h17.5v15h-17.5v17.5h-15v-17.5h-17.5v-15h17.5v-17.5h15v17.5z" />
    </svg>
  )
}

export default TextSpacing
