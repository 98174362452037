import { styled } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { CSSProperties } from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

// css
export const ContainerMain = styled('div', props => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const styleContainerInput: CSSProperties = {
  textAlign: 'left',
  color: '#fff',
  fontFamily: 'Roboto',
  fontWeight: 500,
}
// components
interface Props<T> extends UseControllerProps<T> {
  label: string
  placeholder: string
  typeInput?: 'email' | 'text' | 'number'
  maxLength?: number
  isDisabled?: boolean
}

export const ControladorComponent = <T extends FieldValues>({
  name,
  control,
  label,
  rules,
  placeholder,
  typeInput = 'text',
  maxLength = 27,
  isDisabled = false,
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <FormControl
            overrides={{
              Label: { style: { ...styleContainerInput } },
              Caption: {
                style: {
                  color: !!fieldState.error ? '#f18665' : '#fff',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                },
              },
            }}
            label={label}
            error={fieldState.error?.message}
          >
            <Input
              maxLength={maxLength}
              autoComplete="off"
              placeholder={placeholder}
              type={typeInput}
              error={!!fieldState.error}
              disabled={isDisabled}
              {...field}
              
            />
          </FormControl>
        )
      }}
    ></Controller>
  )
}
