import { styled } from "baseui"

export const Container = styled('header', props => {
  return {
    background: '#EBEBEB',
    padding: '10px 0px',
  }
})

export const LogoContainer = styled('div', props => ({
  maxWidth: '100%',
  padding: '10px',
}))

export const ContainerBox = styled('div', props => ({
  backgroundColor: '#fff',
  border: '1px solid #ccc',
  cursor: 'pointer',
  borderRadius: '.5em',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '180px',
  padding: '10px',
  margin : '0px 10px',
  transition: 'all .2s ease',
  ':hover': {
    ':nth-child(1)': {
      backgroundColor: '#EB0F00',
    },
    ':nth-child(2)': {
      backgroundColor: '#0091CE',
    },
    ':nth-child(3)': {
      backgroundColor: '#F5BD00',
    },
  },
  '@media (max-width : 1136px)' : {
    width : '160px'
  }
}))