import { H3 } from 'baseui/typography'

function LoaderCanvas({ textLoaderCanvas = ''} : {textLoaderCanvas : string}) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(41,145,206,.32)',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <H3 $style={{ color: '#fff', filter: 'drop-shadow(2px 4px 6px black)' }}>{textLoaderCanvas}</H3>
    </div>
  )
}

export default LoaderCanvas
