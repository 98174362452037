import useAppContext from '../../../hooks/useAppContext'
import { H5, ParagraphSmall } from 'baseui/typography'
import ImagenLoader from '../../../components/Image'
import { iImages } from '@/constants/data-games'
import ClassCards from './components'
import { styled } from 'baseui'

const BoxImage = styled('div', props => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: '#fff',
  padding: '10px',
  borderRadius: '.25em',
  maxHeight: '200px',
  boxSizing: 'border-box',
  width: '90%',
  margin: '10px auto',
}))

function Card() {
  const { dataGame, setErrorCard, setNameCard, nameCard } = useAppContext()
  const setCard = (item: iImages) => {
    if (!item.position) {
      setErrorCard(true)
    } else {
      setNameCard(item.typeCard)
      setErrorCard(false)
    }
  }
  const Component = nameCard !== '' ? ClassCards[nameCard] : null
  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <H5 margin={'20px 20px 5px'} color="#fff" $style={{ textAlign: 'center' }}>
        {dataGame?.nameGame}
      </H5>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <ParagraphSmall margin={'5px 0px 15px'} color={'#fff'}>
          {nameCard === '' ? 'Seleccione una tarjeta' : 'Complete sus datos'}
        </ParagraphSmall>
      </div>
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection : 'column', maxHeight : '100%' }}>
        {nameCard === '' ? (
          dataGame.images.map(item => (
            <BoxImage key={`Card_${item.id}`} onClick={() => setCard(item)}>
              <div>
                <b>{item.title}</b>
                <p style={{ fontWeight: 300, fontFamily: 'Roboto', fontSize: 12 }}>{item.description}</p>
              </div>
              <ImagenLoader
                height={'100%'}
                style={{ objectFit: 'contain' }}
                src={item.url}
                alt="image_card"
              />
            </BoxImage>
          ))
        ) : (
          <Component />
        )}
      </div>
    </div>
  )
}
export default Card
