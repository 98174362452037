import { Modal, ModalBody } from 'baseui/modal'
import ImagenLoader from '../Image'



interface iPropsImage {
  isActive: boolean
  closeModal: (value: boolean) => void
  urlImage: string
}

function ModalImage({ isActive = false, closeModal = () => {}, urlImage = '' }: iPropsImage) {
  return (
    <Modal
      overrides={{
        Dialog: { style: { backgroundColor: '#F5F5F5', width: '70%', height: '95vh' } },
        Close: { style: { color: '#0091CE' } },
        Root: { style: { zIndex: 99 } },
      }}
      isOpen={isActive}
      role="dialog"
      unstable_ModalBackdropScroll
      onClose={tipo => {
        console.log(tipo)
        if (tipo.closeSource === 'closeButton') {
          closeModal(false)
        }
      }}
    >
      <ModalBody>
        <div style={{ display: 'flex', maxHeight: '90vh', justifyContent: 'center' }}>
          <ImagenLoader
            autoHeight
            style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
            src={urlImage}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalImage
