import useAppContext from '../../hooks/useAppContext'
import { Button } from 'baseui/button'
import { Modal, ModalBody } from 'baseui/modal'
import { H2, ParagraphMedium } from 'baseui/typography'
import { Icons } from '../../assets/Icons'
import { useState } from 'react'

interface iModalProps {
  isActive: boolean
}

function ModalComplete({ isActive = false }: iModalProps) {
  const { image64, dataUser, dataGame, dataCardRed } = useAppContext()

  const [isDisableButton, setDisabled] = useState(false)
  const [activeButtonClose, setActiveButtonClose] = useState(false)

  const save = () => {
    console.log("save")
    const a = document.createElement('a')
    let name = ''
    if (dataGame.name === 'Tarjetas') {
      name = `${dataCardRed.fullname.replaceAll(' ', '')}_${dataCardRed.date}.png`
    } else {
      name = `${dataUser.fullname.replaceAll(' ', '')}_${dataUser.date}.png`
    }
    a.href = image64;
    a.download = name;
    a.click()
    setTimeout(() => {
      window.close()
      setActiveButtonClose(true)
    //  window.location.reload()
    }, 3000)
    
    

    setDisabled(true)
  }

  const handeCloseWindow = ()=>{
      window.close()
      setTimeout(()=>{
        window.location.reload()
      }, 3000)
  }

  return (
    <Modal
      overrides={{
        Dialog: { style: { backgroundColor: '#F5F5F5', width: '40%' } },
        Close: { style: { color: '#0091CE' } },
        Root: { style: { zIndex: 99 } },
      }}
      isOpen={isActive}
      role="dialog"
      unstable_ModalBackdropScroll
      closeable={false}
    >
      <ModalBody>
        <div style={{ padding: '40px 10px' }}>
          <div style={{ textAlign: 'center' }}>
            <Icons.Check fill="green" height={120} />
            <H2 color={'primary'}>¡Buen trabajo!</H2>
            <ParagraphMedium $style={{ fontWeight: 500 }}>¡Felicitaciones, tu {dataGame.name} es un éxito!</ParagraphMedium>
            <ParagraphMedium>
              Tu archivo final se descargará automáticamente a tu computador cuando finalices la actividad.
              <br />
              Muchas gracias por participar en nuestra fábrica de {dataGame.name}.
              <br />Ya puedes volver a la clase haciendo clic en:</ParagraphMedium>
              {
                !activeButtonClose ?
              <Button disabled={isDisableButton} onClick={save}>
                Finalizar
              </Button>
              :
              <Button onClick={handeCloseWindow}>
              Cerrar Ventana
              </Button>
              }
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalComplete
