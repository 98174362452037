import { styled } from "baseui"
import { H5 } from "baseui/typography"
import bkHome from '../assets/images/bk.png'

const ContainerBottom = styled('div',props => ({
  position : 'absolute', bottom : 0, left : 0, width : '100%', height : '5px', display : 'flex'
}))

const ElementBottom = styled('div', props => ({
  height : '100%', width : '25%'
}))

function Mobile(){
  return (
    <div style={{backgroundColor : '#eeeeee', height : '100vh', width : '100vw', display : 'flex', justifyContent :'center', alignItems : 'center'}}>
      <img
        alt='background-web'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0.1,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        src={bkHome}
      />
       <ContainerBottom>
        <ElementBottom style={{ backgroundColor : '#F5BD00'}}/>
        <ElementBottom style={{ backgroundColor : '#0091CE'}}/>
        <ElementBottom style={{ backgroundColor : '#000000'}}/>
        <ElementBottom style={{ backgroundColor : '#EB0F00'}}/>
      </ContainerBottom>
      <div style={{width : '90%', backgroundColor : '#fff', padding : '10px', borderRadius : '.25em',border : '1px solid #e9e9e9', zIndex : 9}}>
        <H5 $style={{color : '#007BAF', textAlign : 'center', fontFamily : 'Poppins'}}>La aplicación solo puede ser utilizada en tablets y computadoras.</H5>
      </div>
    </div>
  )
}


export default Mobile