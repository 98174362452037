import useAppContext from '../../hooks/useAppContext'
import { styled } from 'baseui'
import ImagenLoader from '../Image'
import { H6, ParagraphSmall } from 'baseui/typography'
import Components from './components/index'
import { Button } from 'baseui/button'
import { useState } from 'react'
import { PanelType } from '../../constants/app-options'
import { toaster } from 'baseui/toast'
import html2canvas from 'html2canvas'
import { ContainerButton } from '../POEV/index.components'

import games from '../../services/games'

const Container = styled('div', () => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0px',
  '::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    borderRadius: '5px',
    height: '10px',
    transition: 'background-color 0.2s linear',
    width: '10px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#aaa',
    backgroundClip: 'padding-box',
    border: '2px solid transparent',
    borderRadius: '5px',
    transition: 'background-color 0.2s linear',
  },
}))

const ContainerGrid = styled('div', () => ({
  width: '95%',
  display: 'grid',
  gridTemplateColumns: '45% 55%',
  columnGap: '10px',
}))

const ContainerColumn = styled('div', () => ({
  border: '1px solid #ccc',
  backgroundColor: '#fff',
  borderRadius: '.25em',
  width: '100%',
  padding: '15px 20px',
  margin: '0px auto',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
}))

function Tarjetas() {
  const {
    dataGame,
    nameCard,
    activePanel,
    dataUser,
    setActivePanel,
    dataCardRed,
    itemsCard,
    setImage64,
    setCompletTast,
  } = useAppContext()
  const [carga, setCarga] = useState(false)

  const ComponentCard = nameCard !== '' ? Components[nameCard] : null

  const validate = (): { active: boolean; message?: string[] } => {
    let active = false,
      message = [],
      isObservation = false

    if (itemsCard.find(item => item.name === 'Otros*' && item.active)) {
      isObservation = true
    }

    active =
      dataCardRed.date !== '' &&
      dataCardRed.line !== '' &&
      dataCardRed.numberCard !== '' &&
      dataCardRed.team !== '' &&
      dataCardRed.fullname !== '' &&
      dataCardRed.email !== ''

    if (isObservation) {
      active = dataCardRed?.observations !== ''
    }

    if (!active) {
      message.push('Falta completar los datos de la tarjeta.')
    }

    if (itemsCard.filter(item => item.active).length === 0) {
      active = false
      message.push('Debe seleccionar por lo menos un tipo de problema.')
    }

    return {
      active,
      message,
    }
  }

  const nextStep = async () => {
    try {
      if (activePanel === 'DataUser') {
        if (dataUser.isComplete) {
          setActivePanel('Cards' as PanelType)
        } else {
          toaster.negative('Falta tu información personal', {})
        }
        return
      }
      setCarga(true)
      // debemos validar por lo menos que este : date,line,numberCard,team, observaciones si en caso esta seleccionado otros
      const validation = validate()
      if (validation.active) {
        const canvas = await html2canvas(document.querySelector('.parentCard'), {
          allowTaint: true,
          useCORS: true,
        })
        const croppedCanvas = document.createElement('canvas')
        const croppedCanvasContext = croppedCanvas.getContext('2d')
        // init data
        const cropPositionTop = 0
        const cropPositionLeft = 0
        const cropWidth = canvas.width
        const cropHeight = canvas.height
        croppedCanvas.width = cropWidth
        croppedCanvas.height = cropHeight
        croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)
        const base64Image = croppedCanvas.toDataURL('image/png', 1)
        await  games.saveData(base64Image,dataCardRed.email,dataCardRed.fullname,dataGame.id,dataGame.name);
        setCarga(false)
        setImage64(base64Image)
        setCompletTast(true)
      } else {
        validation.message.forEach(item => {
          toaster.negative(item, {})
        })
        setCarga(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <ContainerButton>
        <Button isLoading={carga} onClick={nextStep}>
          {activePanel === 'DataUser' ? 'Siguiente' : 'Enviar y revisar'}
        </Button>
      </ContainerButton>
      <ContainerGrid>
        <ContainerColumn>
          <H6 margin={0} color="primaryA">
            Descripción del caso:
          </H6>
          <ParagraphSmall>{dataGame?.descriptionGame}</ParagraphSmall>
          <div
            style={{ width: '100%', height: '1px ', backgroundColor: '#ccc', margin: '5px 0px 8px' }}
          ></div>
          <H6 margin={'0px 0px 10px'} color="primaryA">
            Imagen del caso:
          </H6>
          <ImagenLoader
            src={dataGame.imageCase}
            style={{ width: '100%', objectFit: 'contain', maxHeight: '400px', borderRadius: '.25em' }}
          />
        </ContainerColumn>
        <ContainerColumn>
          {nameCard === '' ? (
            <ParagraphSmall $style={{ textAlign: 'center' }}>
              Seleccione la tarjeta adecuada para continuar.
            </ParagraphSmall>
          ) : (
            <ComponentCard />
          )}
        </ContainerColumn>
      </ContainerGrid>
    </Container>
  )
}

export default Tarjetas
