import {iIcon} from './interfaces/index';
function Elements({ size, color } : iIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        strokeLinejoin="round"
        d="M6.5 4.25l.75-.75a2.121 2.121 0 0 1 3 3L6.5 10.25 2.75 6.5a2.121 2.121 0 0 1 3-3l.75.75zm7 6l4-7.5 4 7.5h-8zm-10.75 3.5h7.5v7.5h-7.5v-7.5zm14.75-.25a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
      ></path>
    </svg>
  )
}

export default Elements
