interface iErrorDetails {
  name: string
  details: string[]
}

function ErrorParams({ details = [], name = '' }: iErrorDetails) {
  return (
    <div style={{ padding: '10px' }}>
      <h3>{name}</h3>
      <ul>
        {details.map((detail, key) => (
          <li key={`detail_${key}`}>{detail}</li>
        ))}
      </ul>
    </div>
  )
}

export default ErrorParams
