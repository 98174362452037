import Undo from "./Undo";
import Redo from "./Redo";
import Search from "./Search";
import FillColor from "./FillColor";
import Delete from "./Delete";
import Duplicate from "./Duplicate";
import Opacity from "./Opacity";
import ToFront from "./ToFront";
import Forward from "./Forward";
import ToBack from "./ToBack";
import Backward from "./Backward";
import Locked from './Locked';
import UnLocked from './Unlocked';
import CopyStyle from './CopyStyle';
import TextAlignCenter from './TextAlignCenter';
import TextAlignRight from './TextAlignRight';
import TextAlignLeft from './TextAlignLeft';
import TextAlignJustify from './TextAlignJustify';
import Bold from './Bold';
import Italic from './Italic';
import TextColor from './TextColor';
import Underline from './Underline';
import Background from './Background'
import Elements from './Elements'
import Text from './Text'
import Templates from './Templates'
import Images from './Images'
import Illustrations from './Illustrations';
import Reload from './Reload';
import Record from './Record';
import Expand from './Expand';
import DataUser from './User';
import Error from './Error';
import Check from './Check';
import ZoomIn from './ZoomIn';
import Eye from './Eye';
import TipoLup from './TypeLup';

export const Icons = {
  Undo,
  Redo,
  Search,
  FillColor,
  Delete,
  Duplicate,
  Opacity,
  ToFront,
  Forward,
  ToBack,
  Backward,
  Locked,
  UnLocked,
  CopyStyle,
  TextAlignCenter,
  TextAlignRight,
  TextAlignLeft,
  TextAlignJustify,
  Bold,
  TextColor,
  Italic,
  Underline,
  Background,
  Elements,
  Text,
  Illustrations,
  Templates,
  Images,
  Reload,
  Record,
  Expand,
  DataUser,
  Error,
  Check,
  Cards : Images,
  ZoomIn,
  Eye,
  TipoLup
};
