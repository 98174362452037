import { AppContext } from '../contexts/AppContext'
import { useContext } from 'react'

function useAppContext() {
  const {
    isMobile,
    setIsMobile,
    activePanel,
    setActivePanel,
    templates,
    setTemplates,
    dataUser,
    setDataUser,
    dataGame,
    setDataGame,
    activeWelcome,
    setActiveWelcome,
    dataCompany,
    setDataCompany,
    stepActive,
    setActiveStep,
    isCompleteTask,
    setCompletTast,
    isErrorCard,
    setErrorCard,
    nameCard,
    setNameCard,
    itemsCard,
    setItemsCard,
    dataCardRed,
    setDataCardRed,
    incorrectTask,
    setIncorrectTask,
    image64,
    setImage64,
    widthWindows,
    setWidthW,
    dataTask,
    setDataTask
  } = useContext(AppContext)
  return {
    isMobile,
    setIsMobile,
    activePanel,
    setActivePanel,
    templates,
    setTemplates,
    dataUser,
    setDataUser,
    dataGame,
    setDataGame,
    activeWelcome,
    setActiveWelcome,
    dataCompany,
    setDataCompany,
    stepActive,
    setActiveStep,
    isCompleteTask,
    setCompletTast,
    isErrorCard,
    setErrorCard,
    nameCard,
    setNameCard,
    itemsCard,
    setItemsCard,
    dataCardRed,
    setDataCardRed,
    incorrectTask,
    setIncorrectTask,
    image64,
    setImage64,
    widthWindows,
    setWidthW,
    dataTask,
    setDataTask
  }
}

export default useAppContext
