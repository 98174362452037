function Reload({ size,color } : {size : number, color : string}) {
  return (
    <svg
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill={color}
    >
      <path
        d="M25.049,40.566l0.003,0.003l17.233-0.003v-6.66h-9.15c4.248-4.453,10.225-7.24,16.865-7.24
	c12.884,0,23.333,10.446,23.333,23.333H80c0-16.569-13.431-30-30-30c-8.499,0-16.159,3.551-21.614,9.232L28.382,20h-6.663v17.233
	C21.719,39.076,23.21,40.566,25.049,40.566z"
      />
      <path
        d="M74.948,59.434v-0.003l-17.233,0.003v6.66h9.147C62.617,70.547,56.641,73.333,50,73.333
	c-12.884,0-23.333-10.445-23.333-23.333H20c0,16.569,13.431,30,30,30c8.496,0,16.159-3.552,21.614-9.231V80h6.667V62.767
	C78.281,60.925,76.79,59.434,74.948,59.434z"
      />
    </svg>
  )
}

export default Reload
