export const panelListItemLUP = [
  {
    id : 'DataUser',
    name : 'DataUser',
    title : 'Datos'
  },
  {
    id: 'tipolup',
    name: 'TipoLup',
    title : 'Tipo'
  },
  {
    id: 'imageGame',
    name: 'Images',
    title : 'Galería'
  },
]

export const panelListItemPOEV = [
  {
    id : 'DataUser',
    name : 'DataUser',
    title : 'Datos'
  },
  {
    id: 'imageGame',
    name: 'Images',
    title : 'Galería'
  },
]

export const panelListItemCARD = [
  {
    id: 'Cards',
    name: 'Cards',
    title : 'Completar'
  },
]

export enum PanelType {
  TEMPLATES = 'DataUser',
  BACKGROUND = 'Background',
}

export const ItemsCardRed = [
  {
    active: false,
    name: 'Lugar de Atrapamiento',
  },
  {
    active: false,
    name: 'Protecciones de posición',
  },
  {
    active: false,
    name: 'Espacio confinado',
  },
  {
    active: false,
    name: 'Switch de seguridad alterado',
  },
  {
    active: false,
    name: 'Peligro eléctrico',
  },
  {
    active: false,
    name: 'Parada de emergencia de mal estado',
  },
  {
    active: false,
    name: 'Caída de distinto nivel',
  },
  {
    active: false,
    name: 'Falta de protección',
  },
  {
    active: false,
    name: 'Fuga de sustancia peligrosa',
  },
  {
    active: false,
    name: 'Otros*',
  },
]