
import { useStyletron } from 'baseui'
import { Icons } from '../../assets/Icons'
import useAppContext from '../../hooks/useAppContext'
import {  ParagraphXSmall } from 'baseui/typography'

function PanelListItem({ label, icon, activePanel, title }) {
  const { stepActive, dataGame } = useAppContext()
  const [css, theme] = useStyletron()
  const Icon = Icons[icon]
 
  return (
    <div
      className={css({
        width: '84px',
        height: '80px',
        backgroundColor: label === activePanel ? theme.colors.primaryA : theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        fontFamily: 'Uber Move Text',
        fontWeight: 500,
        fontSize: '0.8rem',
        userSelect: 'none',
        transition: 'all 0.5s',
        gap: '0.1rem',
        position: 'relative',
        zIndex: dataGame.name === 'LUP' ? 
          (stepActive === 2 && label === 'DataUser') || (stepActive === 3 && label === 'TipoLup') || (stepActive === 4 && label === 'Images') ? 999 : 1
          :
          (stepActive === 2 && label === 'DataUser') || (stepActive === 3 && label === 'Images') ? 999 : 1
          ,
      })}
    >
      <Icon size={24} color={label === activePanel ? '#fff' : theme.colors.primaryA} />
      <div> 
        <ParagraphXSmall
          margin={0}
          $style={{ color: label === activePanel ? '#fff' : theme.colors.primaryA, fontFamily:'Poppins', fontWeight : 700 }}
        >
          {title}
        </ParagraphXSmall>
      </div>
    </div>
  )
}

export default PanelListItem
