import { StyledSpinnerNext } from "baseui/spinner";
import { useEffect } from 'react'

function Loading({ isMain = false }: { isMain: boolean }) {

  useEffect(() => {

    document.querySelector("main").style.height = "100vh"

    return () => {
      document.querySelector("main").style.height = "auto"
    }
  }, [])

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'column',
        height: isMain ? '100vh' : 'auto'
      }}
    >
      <h2>Cargando...</h2>

      <StyledSpinnerNext />
    </div>
  );
}

export default Loading;
