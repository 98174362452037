
import Images from './ImagesGame'
import DataUser from './DataUser';
import Cards from './Cards';
import TipoLup from './TipoLup'

export const PanelItems =  {
  Images,
  DataUser,
  Cards,
  TipoLup
}
 