import { Input } from 'baseui/input'
import { DatePicker } from 'baseui/datepicker'
import es from 'date-fns/locale/es'
import { Checkbox } from 'baseui/checkbox'
import useAppContext from '../../../../hooks/useAppContext'
import { iDataCard, iItemsCard } from '../../../../contexts/AppContext'
import { useEffect, useState } from 'react'
import { Textarea } from 'baseui/textarea'
import { ParagraphSmall } from 'baseui/typography'

const ItemInput = ({
  title,
  isDate = false,
  placeholder = '',
  setData = () => {},
  maxLength = 40,
}: {
  title: string
  maxLength?: number
  isDate?: boolean
  placeholder?: string
  setData?: (e: string) => void
}) => {
  return (
    <div style={{ margin: '6px 0px' }}>
      <ParagraphSmall margin={'0px 0px 5px'} color="#fff" $style={{ fontWeight: '500' }}>
        {title}
      </ParagraphSmall>
      {isDate ? (
        <DatePicker
          onChange={date => {
            if (date.date) {
              try {
                const fecha = date.date.toLocaleString('es-CL').slice(0, 10)
                setData(fecha)
              } catch (error) {
                console.log(error)
              }
            }
          }}
          locale={es}
          overrides={{ WeekdayHeader: { style: { fontFamily: 'Roboto' } } }}
        />
      ) : (
        <Input
          onChange={e => {
            const value = (e.target as any).value
            setData(value)
          }}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      )}
    </div>
  )
}

function InfoCardRed() {
  const { itemsCard, setItemsCard, setDataCardRed } = useAppContext()
  const [isAll, setAll] = useState(false)
  const [isOthers, setOthers] = useState(false)
  const cambioChk = (value: boolean, name: string) => {
    const items: iItemsCard[] = itemsCard.map(item => {
      if (item.name === name) {
        return {
          active: value,
          name,
        }
      }
      return item
    })
    setItemsCard(items)
  }
  const allActive = () => {
    const items: iItemsCard[] = itemsCard.map(item => ({ name: item.name, active: !isAll }))
    setItemsCard(items)
  }

  useEffect(()=>{
    if(!isAll){
      setDataCardRed((data) => ({
        ...data,
        observations : '',
      }))
    }
  },[isAll,setDataCardRed])

  useEffect(() => {
    if (itemsCard.length > 0) {
      setAll(itemsCard.filter(i => i.active).length === itemsCard.length)
      setOthers(!!itemsCard.find(item => item.name === 'Otros*' && item.active))
    }
  }, [itemsCard])

  const setData = (value: string, key: keyof iDataCard) => {
    setDataCardRed((data) => ({
      ...data,
      [key] : value
    }))
  }

  return (
    <div style={{padding : '0px 10px 40px', boxSizing : 'border-box'}}>
      <ItemInput
        title={'Nombre'}
        placeholder="Ingrese sus nombres"
        setData={e => setData(e, 'fullname')}
      />
      <ItemInput title={'Correo'} placeholder="Ingrese su correo" setData={e => setData(e, 'email')} />
      <ItemInput
        title={'Tarjeta N°'}
        placeholder="Ingrese el número"
        setData={e => setData(e, 'numberCard')}
      />
      <ItemInput
        title={'Línea'}
        placeholder="Ingrese la línea"
        setData={e => setData(e, 'line')}
      />
      <ItemInput title={'Equipo'} placeholder="Ingrese el equipo" setData={e => setData(e, 'team')} />
      <ItemInput title={'Fecha'} isDate={true} setData={e => setData(e, 'date')} />
      <div style={{ margin: '5px 0px', position: 'relative', zIndex: 999 }}>
        <span style={{ color: '#fff', marginBottom: '5px', display: 'block', fontWeight: 500 }}>
          Tipo de problemas
        </span>
        <div
          style={{
            border: '1px solid #036792',
            borderRadius: '.25em',
            padding: '10px',
            backgroundColor: '#0278aa',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              marginLeft: 'auto',
              marginBottom: '5px',
              fontWeight: 500,
              textDecoration: 'underline',
              color: '#fff',
              cursor: 'pointer',
            }}
            onClick={() => allActive()}
          >
            {isAll ? 'Desmarcar todas' : 'Marcar todas'}
          </span>
          {itemsCard.map((item, key) => (
            <Checkbox
              key={`panel_card${key}`}
              checked={item.active}
              onChange={() => cambioChk(!item.active, item.name)}
              overrides={{
                Label: { style: { color: '#fff', fontWeight: 400 } },
                Checkmark: {
                  style: {
                    borderTopWidth: '.5px',
                    borderBottomWidth: '.5px',
                    borderLeftWidth: '.5px',
                    borderRightWidth: '.5px',
                    backgroundColor: '#f5bd01',
                  },
                },
              }}
            >
              {item.name}
            </Checkbox>
          ))}
        </div>
      </div>
      {isOthers && (
        <>
          <span style={{ color: '#fff', marginBottom: '5px', display: 'block', fontWeight: 500 }}>
            Observaciones
          </span>
          <Textarea
            placeholder="Ingrese sus observaciones"
            clearOnEscape
            onChange={e => {
              const value = (e.target as any).value
              setData(value, 'observations')
            }}
          />
        </>
      )}
    </div>
  )
}

export default InfoCardRed
