import { iIcon } from "./interfaces"

function Italic({ size, color } : iIcon) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 5.33333V4H8V5.33333H12.236L10.418 18.6667H6V20H16V18.6667H11.764L13.582 5.33333H18Z"
        fill={color}
      />
    </svg>
  )
}

export default Italic
