import { FC } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import { AppProvider } from './contexts/AppContext'
import { theme } from './config/theme'

import { FirebaseAppProvider } from 'reactfire'

import FirebaseConfig from './config/firebaseConfig'

const engine = new Styletron()

const Providers: FC = ({ children }) => {
  return (
    <FirebaseAppProvider firebaseConfig={FirebaseConfig}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <AppProvider>{children}</AppProvider>
        </BaseProvider>
      </StyletronProvider>
    </FirebaseAppProvider>
  )
}

export default Providers
