import { iIcon } from "./interfaces"

function Error({ size,color = '#eb0f00' } : iIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 123 123`}>
      <defs></defs>
      <g transform="translate(-903 -279)">
        <g transform="translate(-586 138.907)">
          {/*a*/}
          <path fill="none" stroke={color} strokeLinecap='round' strokeWidth='16px' d="M6025.063,268.609l30.78,30.78" transform="translate(-4489.563 -82.109)"/>
          <path fill="none" stroke={color} strokeLinecap='round' strokeWidth='16px' d="M6055.844,268.609l-30.78,30.78" transform="translate(-4489.563 -82.109)"/>
        </g>
         {/*b*/}
        <g fill="none" stroke={color} strokeWidth='11px' transform="translate(903 279)">
           {/*c*/}
          <circle stroke="none" cx="61.5" cy="61.5" r="61.5"/>
           {/*d*/}
          <circle fill="none" cx="61.5" cy="61.5" r="56"/>
        </g>
      </g>
    </svg>
  )
}

export default Error

//<style>.a,.b,.d{fill:none;}.a,.b{stroke:#eb0f00;}.a{stroke-linecap:round;stroke-width:16px;}.b{stroke-width:11px;}.c{stroke:none;}</style>