import CardBlue from './CardBlue';
import CardRed from './CardRed';
import CardYellow from './CardYellow';
import CardCommon from './CardCommon';

class Components {
  static CardYellow = CardYellow
  static CardRed = CardRed
  static CardBlue = CardBlue
  static CardCommon = CardCommon
}

export default Components;