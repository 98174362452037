import { Card, CardProps } from 'baseui/card'

function CardCommon(props : CardProps)  {
  return (
    <Card
      {...props}
      overrides={{
        Root: {
          style: {
            height: 'fit-content',
            width: '80%',
            backgroundColor: '#fff',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
            overflow: 'visible',
          },
        },
        Title: {
          style: {
            color: '#007BAF',
          },
        },
      }}
      
    >
      {props.children}
    </Card>
  )
}

export default CardCommon
